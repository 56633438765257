import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import {
  FloorMapComponent,
  TeamsConfigurationsComponent
} from './components';
import { AuthGuard } from '../auth/guards';

const routes: Routes = [
  {
    path: 'teams-configurations',
    canActivate: [AuthGuard],
    component: TeamsConfigurationsComponent
  },
  {
    path: 'teams-floor-map',
    canActivate: [AuthGuard],
    component: FloorMapComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TeamsRoutingModule { }
