import {
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AssetsFilterTypeEnum,
  AuthenticationProvider,
  CpmsRes,
  CustomerTypeEnum,
  TeamsConfigurationsModel
} from '@app/core';
import {
  BUILDING_SEARCH_COLLECTION_TOKEN,
  CUSTOMER_SEARCH_COLLECTION_TOKEN,
  FLOOR_SEARCH_COLLECTION_TOKEN,
  SITE_SEARCH_COLLECTION_TOKEN
} from '@app/shared/controls/assetsBreadcrumbs';
import { AssetsCollectionModel } from '@app/shared/controls/assetsBreadcrumbs/models';
import {
  AssetsSearchCollectionResponseContract,
  AssetsSearchRequestContract
} from '@app/storage';
import {
  app,
  pages
} from "@microsoft/teams-js";
import {
  NkSelect,
  NkSelectItemContract
} from '@nkControls';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'teams-configurations',
  templateUrl: './teamsConfigurations.component.html',
  styleUrls: ['./teamsConfigurations.component.scss']
})

export class TeamsConfigurationsComponent implements OnInit {

  @ViewChild('floorSelector', { static: true }) floorSelector: NkSelect;
  @ViewChild('buildingSelector', { static: true }) buildingSelector: NkSelect;
  @ViewChild('siteSelector', { static: true }) siteSelector: NkSelect;
  @ViewChild('customerSelector', { static: true }) customerSelector: NkSelect;

  public assetsFilterType = AssetsFilterTypeEnum;

  constructor(
    @Inject(FLOOR_SEARCH_COLLECTION_TOKEN) private readonly floorSearchCollectionModel: AssetsCollectionModel,
    @Inject(BUILDING_SEARCH_COLLECTION_TOKEN) private readonly buildingSearchCollectionModel: AssetsCollectionModel,
    @Inject(SITE_SEARCH_COLLECTION_TOKEN) private readonly siteSearchCollectionModel: AssetsCollectionModel,
    @Inject(CUSTOMER_SEARCH_COLLECTION_TOKEN) private readonly customerSearchCollectionModel: AssetsCollectionModel,
    private authenticationProvider: AuthenticationProvider,
    public teamsConfigurationsModel: TeamsConfigurationsModel,
    public res: CpmsRes
  ) {

  }

  public async ngOnInit() {
    await app.initialize();
    const config = await pages.getConfig();
    let entityId = config.entityId;
    this.teamsConfigurationsModel.init(entityId);
    pages.config
      .registerOnSaveHandler(async (saveEvent) => {
        entityId = `${this.teamsConfigurationsModel.customerId}&${this.teamsConfigurationsModel.siteId}&${this.teamsConfigurationsModel.buildingId}&${this.teamsConfigurationsModel.floorId}`;
        this.teamsConfigurationsModel.saveToStorage(entityId);
        const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
        await pages.config
          .setConfig({
            suggestedDisplayName: "Pointgrab " + this.floorSelector.selectedItem.name,
            entityId: `${this.teamsConfigurationsModel.customerId}&${this.teamsConfigurationsModel.siteId}&${this.teamsConfigurationsModel.buildingId}&${this.teamsConfigurationsModel.floorId}`,
            contentUrl: baseUrl + `/teams-floor-map?customerId=${this.teamsConfigurationsModel.customerId}&siteId=${this.teamsConfigurationsModel.siteId}&buildingId=${this.teamsConfigurationsModel.buildingId}&floorId=${this.teamsConfigurationsModel.floorId}`,
            websiteUrl: baseUrl + `/teams-floor-map?customerId=${this.teamsConfigurationsModel.customerId}&siteId=${this.teamsConfigurationsModel.siteId}&buildingId=${this.teamsConfigurationsModel.buildingId}&floorId=${this.teamsConfigurationsModel.floorId}`,
          });
        saveEvent.notifySuccess();
      });

    pages.config.setValidityState(true);
  }

  public getCustomerCollection = (): Observable<Array<NkSelectItemContract>> => {
    const selector = {
      customerId: null,
      siteId: null,
      buildingId: null,
      searchBy: AssetsFilterTypeEnum.customers,
      offset: 0,
      count: 100000
    } as AssetsSearchRequestContract
    return this.customerSearchCollectionModel
      .getSearchCollection(selector, false)
      .pipe(map((t: AssetsSearchCollectionResponseContract) => {
        const list: Array<NkSelectItemContract> = [];
        t.items
          .filter(c => c.customerType === CustomerTypeEnum.END_CUSTOMER)
          .forEach(c => {
            list.push({ id: c.customerId, name: c.customerName });
          })
        return list;
      }));
  }

  public getSiteCollection = (): Observable<Array<NkSelectItemContract>> => {
    const selector = {
      customerId: this.teamsConfigurationsModel.customerId,
      siteId: null,
      buildingId: null,
      searchBy: AssetsFilterTypeEnum.sites,
      offset: 0,
      count: 100000
    } as AssetsSearchRequestContract
    return this.siteSearchCollectionModel
      .getSearchCollection(selector, false)
      .pipe(map((t: AssetsSearchCollectionResponseContract) => {
        const list: Array<NkSelectItemContract> = [];
        t.items.forEach(c => {
          list.push({ id: c.siteId, name: c.siteName });
        })
        return list;
      }));
  }

  public getBuildingCollection = (): Observable<Array<NkSelectItemContract>> => {
    const selector = {
      customerId: this.teamsConfigurationsModel.customerId,
      siteId: this.teamsConfigurationsModel.siteId,
      buildingId: null,
      searchBy: AssetsFilterTypeEnum.buildings,
      offset: 0,
      count: 100000
    } as AssetsSearchRequestContract
    return this.buildingSearchCollectionModel
      .getSearchCollection(selector, false)
      .pipe(map((t: AssetsSearchCollectionResponseContract) => {
        const list: Array<NkSelectItemContract> = [];
        t.items.forEach(c => {
          list.push({ id: c.buildingId, name: c.buildingName });
        })
        return list;
      }));
  }

  public getFloorCollection = (): Observable<Array<NkSelectItemContract>> => {
    const selector = {
      customerId: this.teamsConfigurationsModel.customerId,
      siteId: this.teamsConfigurationsModel.siteId,
      buildingId: this.teamsConfigurationsModel.buildingId,
      searchBy: AssetsFilterTypeEnum.floors,
      offset: 0,
      count: 100000
    } as AssetsSearchRequestContract;

    return this.floorSearchCollectionModel
      .getSearchCollection(selector, false)
      .pipe(map((t: AssetsSearchCollectionResponseContract) => {
        const list: Array<NkSelectItemContract> = [];
        t.items.forEach(c => {
          list.push({ id: c.floorId, name: c.floorName });
        })
        return list;
      }));
  }

  public onSelectAsset(item: any, type: AssetsFilterTypeEnum): void {

    switch (type) {
      case AssetsFilterTypeEnum.floors:

        break;
      case AssetsFilterTypeEnum.buildings:
        this.teamsConfigurationsModel.floorId = null;
        break;
      case AssetsFilterTypeEnum.sites:
        this.teamsConfigurationsModel.floorId = null;
        this.teamsConfigurationsModel.buildingId = null;
        break;
      case AssetsFilterTypeEnum.customers:
        this.teamsConfigurationsModel.floorId = null;
        this.teamsConfigurationsModel.siteId = null;
        this.teamsConfigurationsModel.buildingId = null;
        break;
      default:
        break;
    };
  }

  public logout(): void {
    this.authenticationProvider.logout();
  }

}
