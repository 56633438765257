<div class="login-form-wrapper">
        <div class="login-form-container">
                <form [formGroup]="loginForm"
                      class="login-form">
                        <div class="form-block">
                                <div class="flex-row">
                                        <div class="logo-icon">
                                        </div>
                                </div>
                                <div class="flex-row title">
                                        <div class="flex-columns">
                                                <fa-icon [icon]="{prefix: 'fac',  iconName: 'arrow'}"
                                                         (click)="back()"
                                                         class="action-block fa-icon"></fa-icon>
                                        </div>
                                        <div class="flex-columns">
                                                <label class="label-text">{{res.singleSignOne}}</label>
                                        </div>
                                </div>
                                <div class="flex-row">
                                        <div class="value-block">
                                                <input formControlName="email"
                                                       id="email"
                                                       autocomplete="email"
                                                       placeholder="{{res.email}}"
                                                       class="text-form" />
                                                <validation-messages formControlName="email"
                                                                     [required]="res.validationMessages?.emailIsRequired">
                                                </validation-messages>
                                        </div>
                                </div>
                        </div>
                        <div class="form-block buttons">
                                <input (click)="login()"
                                       [disabled]="isDisabledLogin()"
                                       type="button"
                                       value="{{res.signIn}}"
                                       class="button primary-button" />
                        </div>
                        <div class="alert alert-danger">{{error}}</div>
                </form>
        </div>
</div>