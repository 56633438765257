import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    NavigationEnd,
    Router
} from '@angular/router';
import {
    OAuthService
} from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { timer } from 'rxjs';
import {
    filter,
    switchMap,
    take,
    tap
} from 'rxjs/operators';
import { AUTH_APPLICATION_CONFIG } from './modules/auth/configuration';
import {
    GoogleAnalyticsService,
    SubscriptionNotifier
} from './shared';
import { ROUTER_TRANSITION } from './shared/constants';

//import * as LogRocket from 'logrocket';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';

if (environment.logRocket.enable) {
   // LogRocket.init('8xhsdp/pointgrab-test');
}


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [ROUTER_TRANSITION],
})
export class AppComponent implements OnDestroy, OnInit {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();
    public title = 'PointOfView';

    constructor(
        translate: TranslateService,
        private oauthService: OAuthService,
        private router: Router,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.configure();
        translate.setDefaultLang('en');
        translate.use('en');
    }

    public ngOnInit(): void {
        this.googleAnalyticsInit();
    }

    public getState(outlet: any) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    private configure() {
        this.oauthService.configure(AUTH_APPLICATION_CONFIG());
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this.oauthService.setupAutomaticSilentRefresh();
    }

    public googleAnalyticsInit() {
        this.googleAnalyticsService.init();
        timer(500)
            .pipe(
                filter(() => Object.prototype.hasOwnProperty.call(window, 'ga')),
                take(1),
                switchMap(() => this.router.events.pipe(
                    filter((e) => e instanceof NavigationEnd),
                    tap((e: NavigationEnd) => {
                        this.googleAnalyticsService.logPageView(e.url);
                    })
                ))
            )
            .pipe(this.subscriptions.register())
            .subscribe();
    }


}
