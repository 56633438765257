import {
  APP_INITIALIZER,
  NgModule
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import {
  OAuthModule,
  OAuthStorage
} from 'angular-oauth2-oidc';
import { environment } from '@environments/environment';
import {
  AuthStorageRemoteProvider,
  UsersStorageLocalProvider,
  UsersStorageRemoteProvider
} from '@app/storage';
import {
  AuthenticationProvider,
  AuthenticationMockProvider,
  PermissionsProvider,
  ConfigSSOProvider
} from '@app/core';
import { AuthRoutingModule } from './authRouting.module';
import {
  LoginPageComponent,
  LoginByMicrosoftComponent,
  LoginInternalComponent,
  LoginBySSOComponent,
  LoginBySSOCallbackComponent
} from './components';
import {
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE
} from '@azure/msal-angular';
import {
  initializerSSOFactory,
  MSALGuardConfigFactory
} from './configuration/authSSOApplication.config';
import {
  MSALInstanceFactory
} from './configuration/authSSOApplication.config';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthProxyInterceptor } from './interceptors';

export const isMock = environment.mock;
export const storageFactory = (): OAuthStorage => localStorage;

const COMPONENTS = [
  LoginPageComponent,
  LoginByMicrosoftComponent,
  LoginBySSOComponent,
  LoginInternalComponent,
  LoginBySSOCallbackComponent
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    OAuthModule.forRoot(
      {
        resourceServer: {
          allowedUrls: [
            environment.apiWebPrefix,
            environment.apiWebPrefixV2,
            environment.apiWebPrefixV3
          ],
          sendAccessToken: true
        }
      }
    ),
    MsalModule,
    AuthRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthProxyInterceptor,
      multi: true
    },
    UsersStorageRemoteProvider,
    UsersStorageLocalProvider,
    {
      provide: AuthenticationProvider,
      useClass: !isMock ? AuthenticationProvider : AuthenticationMockProvider
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    PermissionsProvider,
    AuthStorageRemoteProvider,
    {
      provide: APP_INITIALIZER, 
      useFactory: initializerSSOFactory,
      deps: [ConfigSSOProvider], 
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [ConfigSSOProvider]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalBroadcastService,
    ConfigSSOProvider
  ]
})
export class AuthModule { }
