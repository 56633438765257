import {
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {
    ConfigSSOProvider,
    ProvidersEnum
} from '@app/core';
import { GlobalRes } from '@app/core/i18n';
import {
    ErrorHandlerService,
    PopupsDialogService,
    SubscriptionNotifier
} from '@app/shared';
import {
    AuthStorageRemoteProvider,
    SSOSettingsContract
} from '@app/storage';
import {
    MsalGuardConfiguration,
    MsalService,
    MSAL_GUARD_CONFIG
} from '@azure/msal-angular';
import {
    RedirectRequest
} from '@azure/msal-browser';
import {
    HOME_UNAUTHORIZED_ROUTE
} from '@appConstants';
import {
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { environment } from '@environments/environment';

@Component({
    selector: 'login-by-microsoft',
    templateUrl: 'loginByMicrosoft.component.html',
    styleUrls: ['./loginByMicrosoft.component.scss'],
    host: {
        class: 'login-by-microsoft'
    }
})

export class LoginByMicrosoftComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();
    public get formControls() { return this.loginForm.controls; }
    public loginForm: FormGroup;
    public error: string;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private msalService: MsalService,
        private activatedRoute: ActivatedRoute,
        private authStorageRemoteProvider: AuthStorageRemoteProvider,
        private configSSOProvider: ConfigSSOProvider,
        private popups: PopupsDialogService,
        private elementRef: ElementRef,
        private errorHandlerService: ErrorHandlerService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        public res: GlobalRes
    ) {
        this.error = '';
        this.loginForm = this.formBuilder.group({
            tenantName: ['', Validators.required]
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public async ngOnInit(): Promise<void> {
        this.loginForm.controls.tenantName.setValue(this.configSSOProvider.authOptions.lastTenantName);
        await this.msalService.instance.initialize();
        (this.msalService.instance as any).controller.browserStorage.clear();
        if (this.configSSOProvider.authOptions.error) {
            this.loginForm.controls.tenantName.setValue(this.configSSOProvider.authOptions.tenantName);
            this.error = this.configSSOProvider.authOptions.error;
            this.configSSOProvider.authOptions.error = '';
        }
        if (this.activatedRoute.snapshot.params.tenantName) {
            this.loginForm.controls.tenantName.setValue(this.activatedRoute.snapshot.params.tenantName);
            this.login();
        }
    }

    public isDisabledLogin(): boolean {
        return !this.loginForm.valid;
    }

    public back(): void {
        this.router.navigate([HOME_UNAUTHORIZED_ROUTE], { queryParams: this.activatedRoute.snapshot.queryParams.redirect_uri ? { redirect_uri: this.activatedRoute.snapshot.queryParams.redirect_uri } : {} });
    }

    public login() {
        this.error = '';
        this.popups.startRelativeLoading(this.elementRef.nativeElement, 100, 'relative-loading-alt');
        this.authStorageRemoteProvider
            .getSSOSettings(this.formControls.tenantName.value)
            .pipe(this.subscriptions.register())
            .subscribe({
                next: (value: SSOSettingsContract) => {
                    if (ProvidersEnum.azure === value.provider) {
                        this.configSSOProvider.authOptions.clientId = value.clientId;
                        this.configSSOProvider.authOptions.authority = value.authority;
                        this.configSSOProvider.authOptions.redirectUri = this.activatedRoute.snapshot.queryParams.redirect_uri;
                        this.configSSOProvider.authOptions.tenantName = this.formControls.tenantName.value;
                        this.configSSOProvider.saveSettings();
                        this.loginRedirect(value);
                    } else {
                        window.location.href = value.authority;
                    }
                },
                error: error => {
                    this.error = this.errorHandlerService.getUserMessage(error) || this.res.organizationNameIsWrong;
                    this.popups.breackRelativeLoading(this.elementRef.nativeElement);
                }
            });
    }

    private loginRedirect(settings: SSOSettingsContract) {

        const config = this.msalService.instance.getConfiguration();
        config.auth.clientId = settings.clientId;
        //TODO:SM: no possibility to set clientId after initialization PublicClientApplication
        (this.msalService.instance as any).controller.browserStorage.clientId = settings.clientId;
        config.auth.authority = settings.authority;
        const redirectUri = '/' + environment.sso.tokenSSOCallbackPage;
        config.auth.redirectUri = window.location.origin + redirectUri;
        try {
            this.msalService.loginRedirect({
                prompt: 'select_account',
                ...this.msalGuardConfig.authRequest
            } as RedirectRequest);
        } catch (error) {
            this.error = this.errorHandlerService.getUserMessage(error) || this.res.organizationNameIsWrong;
            this.popups.breackRelativeLoading(this.elementRef.nativeElement);
        }

    }

}
