import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const AUTH_APPLICATION_CONFIG = (): AuthConfig => {
  const config = {
    useHttpBasicAuth: true,
    clientId: environment.sso.clientId,
    redirectUri: environment.sso.redirectUri,
    logoutUrl: window.location.origin + '/' + environment.sso.logout,
    postLogoutRedirectUri: environment.apiUrl + environment.sso.logout,
    loginUrl: window.location.origin + '/' + environment.sso.loginUrl,
    scope: 'openid profile email',
    resource: '',
    rngUrl: '',
    oidc: false,
    requestAccessToken: true,
    options: null,
    issuer: environment.apiUrl + environment.sso.issuer,
    clearHashAfterLogin: true,
    tokenEndpoint: environment.apiUrl + environment.sso.tokenEndpoint,
    userinfoEndpoint: environment.apiUrl + environment.sso.userinfoEndpoint,
    responseType: 'code',
    showDebugInformation: false,
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    silentRefreshMessagePrefix: '',
    silentRefreshShowIFrame: false,
    silentRefreshTimeout: 60000,
    dummyClientSecret: '',
    requireHttps: false,
    strictDiscoveryDocumentValidation: false,
    jwks: {
      keys: [
        {
          kty: 'RSA',
          alg: 'RS256',
          use: 'sig',
          kid: '7540561fdb04b89d824a1b7b9e8849873e7cb50e',
          n:
            // eslint-disable-next-line max-len
            'sSFZrLIrXzvXBCehdPR10T-mfHWFU5ZtGzW9buI7wT_tJzZ1SRUc2l1NH92kGV9bmWRtDLjWcWFwMG7rbjX25-R-62lD1k15gQiO4bhx7gbV05e36os2vXTs0ypj9GS9y8X_2fYAnxxulMLwz4m24Ejo2tQI43-V-3Tec6cSXe0FjhRaPbGdS8GHPDKkhpJ1NHMZ38vhddIImOfvtVuz3lt_zwjBsAC6Q7PHs2GOm3KtC22DCwXMYSri4QOQcasuvTlZxIQSIksTyuH0T02IH5SJvQZSx46Vfq8BM4JP-zEEjzadoyxQPouRM6TrUeaqNv5B1f1lbH6G0G_r_ddYWQ',
          e: 'AQAB'
        },
        {
          kty: 'RSA',
          alg: 'RS256',
          use: 'sig',
          kid: '778233e8f6f342ea09e867aad25f543adeebf372',
          n:
            // eslint-disable-next-line max-len
            '8MMxQ9F7R1zJ57QvLX-HqUlTVLLofCzZ3-lxohJr8ivJDGZoCqll7ZTNO0nGMgnPpIO-3BQLkaNGQDCpnID1vNIjClFFl0E3cN5bDX15uxCQeQDsm25fTlphpy5FkdoHCviswtrsl2KKUPeRlKqCqMjlDO27KuxIwzIPdNSqv4tseZmI-biFt2JlO9htgODrVqaawdm27t9HcWfOK_a5czRFDHWck2-ZwjbCOF9CtF1ggYm11aV0TElExXr5fgjAQdZ1yGmJvir127BRUgyIy5cpyf7VRRf2Cv7whSMoVJr4W3OK0H9vkuFLnlBiBNYQmH_eWy5U4jBfZjBqvA7Oww',
          e: 'AQAB'
        },
        {
          kty: 'RSA',
          alg: 'RS256',
          use: 'sig',
          kid: '8ec17994394464d95b0b3d906326f1cdde8aee64',
          n:
            // eslint-disable-next-line max-len
            'w49KfvzGWVXH4vyUxvP29_QTmJfvLp4RPT1WlI6Wo2aNvn6j9vRSLDrK2CnOvvrrlUKvR-8FTcyNi9pRKXDwDhEJcyVFBJVi4PqDh0KIX_dOGYCulr5FUvU0HXQxlMWSHIsJjfGbMMUwM0p09y8KHL-kipiipzn80EpBmrI4Q3t6XOAZJSmbIPaGZJDjyoWWV0TDdVDBMfkqII6tOOB7Ha189AZjz7FHYXR9CIc0Jm6rFy0tVpdHFEG3ptcNQEDQ5ghyMM4PDM4ZmQ5uk3WgHVqnpdmGEfKekLwmYFWgnI-ux_MabltIxr9TE1qubEmebM64rOusHBF0mSbEwggbyw',
          e: 'AQAB'
        }
      ]
    },
    customQueryParams: null,
    silentRefreshIFrameName: 'angular-oauth-oidc-silent-refresh-iframe',
    timeoutFactor: 0.75,
    sessionCheckIntervall: 3000,
    sessionCheckIFrameName: 'angular-oauth-oidc-check-session-iframe',
    disableAtHashCheck: false,
    skipSubjectCheck: false
  };
  return config;
};
