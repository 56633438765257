import {
    Component,
    AfterViewInit,
    OnDestroy,
    ElementRef
} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { PopupsDialogService } from '@appControls';
import { AuthenticationProvider } from 'src/app/core';
import { TokenContract } from 'src/app/storage';
import { SubscriptionNotifier } from '@appModels';
import { GlobalRes } from '@app/core/i18n';
import { AuthRoutesConstants } from '../../constants';

@Component({
    selector: 'login-internal',
    templateUrl: 'loginInternal.component.html',
    styleUrls: ['./loginInternal.component.scss'],
    host: {
        class: 'login-internal'
    }
})

export class LoginInternalComponent implements AfterViewInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    public loginForm: FormGroup;
    public submitted: boolean;
    public error: string;
    public get formControls() { return this.loginForm.controls; }

    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationProvider,
        private popups: PopupsDialogService,
        private elementRef: ElementRef,
        public res: GlobalRes
    ) {
        this.submitted = false;
        this.error = '';
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngAfterViewInit(): void {
        this.authenticationService.logout(true);
        setTimeout(() => {
            this.loginForm.updateValueAndValidity();
        }, 500);
    }

    public loginSSOWithMicrosoft(): void {
        this.router.navigate([AuthRoutesConstants.LOGIN_SSO_ROUTE], { queryParams: this.activatedRoute.snapshot.queryParams.redirect_uri ? { redirect_uri: this.activatedRoute.snapshot.queryParams.redirect_uri } : {} });
    }

    public loginSSO(): void {
        this.router.navigate([AuthRoutesConstants.LOGIN_SAML_SSO_ROUTE], { queryParams: this.activatedRoute.snapshot.queryParams.redirect_uri ? { redirect_uri: this.activatedRoute.snapshot.queryParams.redirect_uri } : {} });
    }

    public login() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.popups.startRelativeLoading(this.elementRef.nativeElement, 100, 'relative-loading-alt');
        this.error = null;
        this.authenticationService
            .login(this.formControls.username.value, this.formControls.password.value)
            .pipe(first())
            .pipe(this.subscriptions.register())
            .subscribe({
                next: (data: TokenContract) => {
                    const returnUrl = this.activatedRoute.snapshot.queryParams.redirect_uri || this.authenticationService.getDefaultRoutApp();
                    window.location.href = returnUrl;
                },
                error: response => {
                    switch (response.status) {
                        case 423:
                            this.error = this.res.userLocked;
                            break;
                        default:
                            if (response.error?.errorCode === '403.6') {
                                this.error = '';
                                this.popups.accessDenied(response.error.message);
                            } else {
                                this.error = this.res.noUser;
                            }
                            break;
                    }
                    this.popups.breackRelativeLoading(this.elementRef.nativeElement);
                }
            });
    }

    public isDisabledLogin(): boolean {
        return !this.loginForm.valid && this.loginForm.dirty;
    }

}
