import { NgModule } from '@angular/core';
import {
    RouterModule,
    Routes
} from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonControlsModule } from 'src/app/shared/controls/commonControls.module';
import { ComparativeAnalysisPermission } from '../auth/guards';
import { ComparativeAnalysisResResolver } from './resolvers';
import { ComparativeAnalysisRes } from '@app/core';
import { MainLayoutComponent } from 'src/app/layout';

const routes: Routes = [
    {
        path: 'analytics/comparativeAnalysis',
        component: MainLayoutComponent,
        canActivate: [ComparativeAnalysisPermission],
        resolve: { i18n: ComparativeAnalysisResResolver },
        loadChildren: () => import('./comparativeAnalysis.module').then(m => m.ComparativeAnalysisModule),
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        CommonControlsModule
    ],
    exports: [
        RouterModule,
        CommonControlsModule
    ],
    providers: [
        ComparativeAnalysisRes
    ]
})
export class ComparativeAnalysisLazyModule {

}
