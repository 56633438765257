import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  RouteReuseStrategy,
  RouterModule,
  provideRouter,
  withDebugTracing,
  withRouterConfig
} from '@angular/router';
import { ComMobileAppComponent } from './comMobileApp.component';
import {
  provideIonicAngular,
  IonicRouteStrategy
} from '@ionic/angular/standalone';
import { IonicAppComponent } from './ionicApp/ionicApp.component';
import { IonicModule } from '@ionic/angular';
import { ComMobileAppRoutes } from './comMobileApp.routing';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

@NgModule({
  declarations: [ComMobileAppComponent],
  imports: [
    IonicModule.forRoot(),
    BrowserModule,
    IonicAppComponent
  ],
  exports: [ComMobileAppComponent],
  providers: [
    provideIonicAngular({
      mode: 'ios'
    }),
    provideRouter(
      ComMobileAppRoutes
      //withDebugTracing(),
      //withRouterConfig({ paramsInheritanceStrategy: 'always' })
    ),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ]
})
export class ComMobileAppModule {
  constructor() {
    defineCustomElements(window);
  }
}
