<div class="outlet-wrapper"
     [@routerTransition]="getState(o)">
  <router-outlet #o="outlet">
  </router-outlet>
</div>
<div class="welcome-area">
  <div class="welcome-content">
    <div class="welcome-icon">
    </div>
  </div>
</div>