import {
    Component,
    NgModule,
    inject
} from '@angular/core';
import {
    NoPreloading,
    RouterModule,
    Routes
} from '@angular/router';
import {
    MainLayoutComponent,
    Page404Component
} from './layout';
import {
    DefaultGuard
} from './modules/auth/guards';

@Component({
    template: ''
})
export class EmptyComponent {
}

const routes: Routes = [
    {
        path: '',
        component: EmptyComponent,
        canActivate: [() => inject(DefaultGuard).canActivate()],
        pathMatch: 'full'
    },
    {
        path: '**',
        component: MainLayoutComponent,
        children: [{ path: '', component: Page404Component }]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            //onSameUrlNavigation: 'reload',
            paramsInheritanceStrategy: 'always',
            //scrollPositionRestoration: 'top',
            //urlUpdateStrategy: 'eager',
          })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
