import { ConfigSSOProvider } from '@app/core';
import { MsalGuardConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';

export function MSALInstanceFactory(config: ConfigSSOProvider): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: config.authOptions.clientId || 'xxx',//'0b9f54b0-e1fb-407f-99d5-b869129f6b54',
      authority: '',
      redirectUri: '',
      postLogoutRedirectUri: window.location.origin + '/login'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.info(message);
}

export function initializerSSOFactory(env: ConfigSSOProvider): any {
  const promise = env.init().then((value) => {
      console.info('finished getting configurations dynamically.');
  });
  return () => promise;
}
