import {
    inject,
    NgModule
} from '@angular/core';
import {
    RouterModule,
    Routes
} from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonControlsModule } from 'src/app/shared/controls/commonControls.module';
import {
    MainLayoutComponent
} from 'src/app/layout';
import { AnalyticsPermission } from '../auth/guards';
import {
    AnalyticsRes,
    FloorPlanRes
} from '@app/core';
import { GlobalResResolver } from '@app/shared';

const routes: Routes = [
    {
        path: 'analytics',
        component: MainLayoutComponent,
        canActivate: [AnalyticsPermission],
        resolve: { i18n: () => inject(GlobalResResolver).resolve() },
        loadChildren: () => import('./analytics.module').then(m => m.AnalyticsModule),
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        CommonControlsModule
    ],
    exports: [
        RouterModule,
        CommonControlsModule
    ],
    providers: [
        AnalyticsRes,
        FloorPlanRes
    ]
})
export class AnalyticsLazyModule {

}
