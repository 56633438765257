import { NgModule } from '@angular/core';
import { AnalyticsLazyModule } from './analytics/analyticsLazy.module';
import { AuthModule } from './auth/auth.module';
import { CpmsLazyModule } from './cpms/cpmsLazy.module';
import { StatusMonitorLazyModule } from './statusMonitor/statusMonitorLazy.module';
import { LicenseModule } from './license/license.module';
import { ComparativeAnalysisLazyModule } from './comparativeAnalysis/comparativeAnalysisLazy.module';
import { TeamsModule } from './teams/teams.module';
import { IntegrationsLazyModule } from './integrations/integrationsLazy.module';
import { CommissioningReportsLazyModule } from './commissioningReports/commissioningReportsLazy.module';

const modules = [
  AuthModule,
  TeamsModule,
  ComparativeAnalysisLazyModule,
  AnalyticsLazyModule,
  StatusMonitorLazyModule,
  LicenseModule,
  CpmsLazyModule,
  IntegrationsLazyModule,
  CommissioningReportsLazyModule
];

@NgModule({
  imports: modules,
  exports: modules,
})
export class ApplicationReferencesModule { }
