import {
    Component,
    OnInit
} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {
    ConfigSSOProvider,
    GlobalRes
} from '@app/core';
import {
    ErrorHandlerService,
    SubscriptionNotifier
} from '@app/shared';
import {
    MsalService
} from '@azure/msal-angular';
import { environment } from '@environments/environment';
import {
    map,
    mergeMap
} from 'rxjs/operators';
import { AuthRoutesConstants } from '../../constants';
import { AuthenticationProvider } from '@app/core/auth/providers';
import { BrowserCacheManager } from '@azure/msal-browser/dist/cache/BrowserCacheManager';

@Component({
    selector: 'login-by-microsoft-callback',
    template: '',
    host: {
        class: 'login-by-microsoft-callback'
    }
})

export class LoginByMicrosoftCallbackComponent implements OnInit {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    constructor(
        private configSSOProvider: ConfigSSOProvider,
        private activatedRoute: ActivatedRoute,
        private authenticationProvider: AuthenticationProvider,
        private router: Router,
        private msalService: MsalService,
        private errorHandlerService: ErrorHandlerService,
        private res: GlobalRes
    ) {
    }

    public async ngOnInit() {
        await this.res.init();
        let redirectUri = '/' + environment.sso.tokenSSOCallbackPage;
        redirectUri = window.location.origin + redirectUri;
        this.activatedRoute
            .fragment
            .pipe(map(value => {
                const browserCacheManager = (this.msalService.instance as any).controller.browserStorage as BrowserCacheManager;
                const requestParams = browserCacheManager.getTemporaryCache('msal.' + this.configSSOProvider.authOptions.clientId + '.request.params') || '';
                const codeVerifier = requestParams ? JSON.parse(atob(requestParams)).codeVerifier : '';
                this.configSSOProvider.authOptions.codeVerifier = codeVerifier;
                const authCode = value.split('=')[1].split('&')[0];
                if (authCode == 'consent_required') {
                    this.configSSOProvider.authOptions.error = this.res.accessDenied;
                    this.router.navigate([AuthRoutesConstants.LOGIN_SSO_ROUTE]);
                } else {
                    this.configSSOProvider.authOptions.authCode = authCode;
                    this.configSSOProvider.saveSettings();
                }
            }))
            .pipe(mergeMap(() => this.authenticationProvider.loginUsingByAuthCode(
                this.configSSOProvider.authOptions.authCode,
                this.configSSOProvider.authOptions.tenantName,
                redirectUri,
                this.configSSOProvider.authOptions.codeVerifier
            )))
            .pipe(this.subscriptions.register())
            .subscribe({
                next: () => {
                    (this.msalService.instance as any).controller.browserStorage.clear();
                    window.location.href = this.configSSOProvider.authOptions.redirectUri || this.authenticationProvider.getDefaultRoutApp();
                },
                error: error => {
                    this.configSSOProvider.authOptions.error = this.errorHandlerService.getUserMessage(error) || this.res.organizationNameIsWrong;
                    (this.msalService.instance as any).controller.browserStorage.clear();
                    this.router.navigate([AuthRoutesConstants.LOGIN_SSO_ROUTE]);
                }
            });
    }

}
