<div [formGroup]="teamsConfigurationsModel.form"
    class="form-block">
    <div class="flex-row">
        <div class="flex-columns logout">
            <input (click)="logout()"
                type="button"
                value="Logout"
                class="button primary-button">
        </div>
    </div>
    <div class="flex-row">
        <div class="flex-columns">
            <label class="label-text">{{res.customer}}</label>
            <div class="value-block">
                <nk-select #customerSelector
                    class="asset-select nk-select-default"
                    formControlName="customerId"
                    [nkSelectDataSource]="getCustomerCollection"
                    [nkSelectPerfectScroll]="true"
                    [nkSelectCache]="false"
                    [nkSelectPrimaryKey]="'id'"
                    [appendTo]="'self'"
                    (nkSelectOnSelectItem)="onSelectAsset($event, assetsFilterType.customers)">
                    <ng-template #itemTemplate
                        let-controlItem="controlItem">
                        <div class="field-item text-overflow"
                            title="{{controlItem.name}}">
                            {{controlItem.name}}
                        </div>
                    </ng-template>
                </nk-select>
            </div>
        </div>
    </div>
    <div class="flex-row">
        <div class="flex-columns">
            <label class="label-text">{{res.site}}</label>
            <div class="value-block">
                <nk-select #siteSelector
                    class="asset-select nk-select-default"
                    formControlName="siteId"
                    [nkSelectDataSource]="getSiteCollection"
                    [nkSelectPerfectScroll]="true"
                    [nkSelectCache]="false"
                    [nkSelectPrimaryKey]="'id'"
                    [appendTo]="'self'"
                    (nkSelectOnSelectItem)="onSelectAsset($event, assetsFilterType.sites)">
                    <ng-template #itemTemplate
                        let-controlItem="controlItem">
                        <div class="field-item text-overflow"
                            title="{{controlItem.name}}">
                            {{controlItem.name}}
                        </div>
                    </ng-template>
                </nk-select>
            </div>
        </div>
    </div>
    <div class="flex-row">
        <div class="flex-columns">
            <label class="label-text">{{res.building}}</label>
            <div class="value-block">
                <nk-select #buildingSelector
                    class="asset-select nk-select-default"
                    formControlName="buildingId"
                    [nkSelectDataSource]="getBuildingCollection"
                    [nkSelectPerfectScroll]="true"
                    [nkSelectCache]="false"
                    [nkSelectPrimaryKey]="'id'"
                    [appendTo]="'self'"
                    (nkSelectOnSelectItem)="onSelectAsset($event, assetsFilterType.buildings)">
                    <ng-template #itemTemplate
                        let-controlItem="controlItem">
                        <div class="field-item text-overflow"
                            title="{{controlItem.name}}">
                            {{controlItem.name}}
                        </div>
                    </ng-template>
                </nk-select>
            </div>
        </div>
    </div>
    <div class="flex-row">
        <div class="flex-columns">
            <label class="label-text">{{res.floor}}</label>
            <div class="value-block">
                <nk-select #floorSelector
                    class="asset-select nk-select-default"
                    formControlName="floorId"
                    [nkSelectDataSource]="getFloorCollection"
                    [nkSelectPerfectScroll]="true"
                    [nkSelectCache]="false"
                    [nkSelectPrimaryKey]="'id'"
                    [appendTo]="'self'"
                    (nkSelectOnSelectItem)="onSelectAsset($event, assetsFilterType.floors)">
                    <ng-template #itemTemplate
                        let-controlItem="controlItem">
                        <div class="field-item text-overflow"
                            title="{{controlItem.name}}">
                            {{controlItem.name}}
                        </div>
                    </ng-template>
                </nk-select>
            </div>
        </div>
    </div>
</div>