import { NgModule } from '@angular/core';
import {
    RouterModule,
    Routes
} from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonControlsModule } from 'src/app/shared/controls/commonControls.module';
import { IntegrationsPermission } from '../auth/guards';
import { IntegrationsResResolver } from './resolvers';
import { IntegrationsRes } from '@app/core';
import { MainLayoutComponent } from 'src/app/layout';

const routes: Routes = [
    {
        path: 'integrations',
        component: MainLayoutComponent,
        canActivate: [IntegrationsPermission],
        resolve: { i18n: IntegrationsResResolver },
        loadChildren: () => import('./integrations.module').then(m => m.IntegrationsModule),
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        CommonControlsModule
    ],
    exports: [
        RouterModule,
        CommonControlsModule
    ],
    providers: [
        IntegrationsRes
    ]
})
export class IntegrationsLazyModule {

}
