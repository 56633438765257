<div class="login-form-wrapper">
  <div class="login-form-container">
    <form [formGroup]="loginForm"
      class="login-form">
      <div class="form-block">
        <div class="flex-row">
          <div class="logo-icon">
          </div>
        </div>
        <div class="flex-row title">
          <div class="flex-columns">
            <label class="label-text">{{res.signIn}}</label>
          </div>
        </div>
        <div class="flex-row">
          <div class="value-block">
            <input id="username"
              type="username"
              formControlName="username"
              placeholder="{{res.username}}"
              class="text-form"
              [ngClass]="{ 'is-invalid': submitted && formControls.username.errors }" />
            <validation-messages formControlName="username"
              [required]="res.validationMessages?.usernameIsRequired">
            </validation-messages>
          </div>
        </div>
      </div>
      <div class="form-block">
        <div class="flex-row">
          <div class="value-block">
            <div class="input-wrapper">
              <input id="password"
                #passwordInput
                type="password"
                placeholder="{{res.password}}"
                formControlName="password"
                class="text-form"
                [ngClass]="{ 'is-invalid': submitted && formControls.password.errors }" />
            </div>
            <validation-messages formControlName="password"
              [required]="res.validationMessages?.passwordIsRequired">
            </validation-messages>
          </div>
        </div>
      </div>
      <div class="form-block buttons">
        <input [disabled]="isDisabledLogin()"
          type="button"
          (click)="login()"
          value="{{res.signIn}}"
          class="button primary-button" />
          <div (click)="loginSSOWithMicrosoft()"
          class="button login-sso secondary-button">
          <div class="logo-sso-icon">
          </div>
          <span>
            {{res.singleSignOne}}
          </span>
        </div>
        <!-- <div (click)="loginSSOWithMicrosoft()"
          class="button login-sso secondary-button">
          <div class="logo-ms-icon">
          </div>
          <span>
            {{res.singleSignOnWithAzure}}
          </span>
        </div>
        <div (click)="loginSSO()"
          class="button login-sso secondary-button">
          <div class="logo-sso-icon">
          </div>
          <span>
            {{res.singleSignOne}}
          </span>
        </div> -->
      </div>

      <div *ngIf="error"
        class="alert alert-danger">{{error}}</div>
    </form>
  </div>
</div>