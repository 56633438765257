import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FloorModel,
  FloorPlanModel,
  PlanViewProvider
} from '@app/core';
import {
  FLOOR_PLAN_LIVE_MODE_TOKEN,
  FLOOR_PLAN_XY_LOCATION_LIVE_MODE_TOKEN
} from '@app/modules/analytics/tokens';
import {
  ErrorHandlerService,
  LiveProcessingService,
  PopupsDialogService,
  SubscriptionNotifier
} from '@app/shared';
import { Inject } from '@angular/core';
import {
  map,
  mergeMap
} from 'rxjs/operators';
import { environment } from '@environments/environment';
import {
  BUILDING_SEARCH_COLLECTION_TOKEN,
  CUSTOMER_SEARCH_COLLECTION_TOKEN,
  FLOOR_SEARCH_COLLECTION_TOKEN,
  SITE_SEARCH_COLLECTION_TOKEN
} from '@app/shared/controls/assetsBreadcrumbs';
import { AssetsCollectionModel } from '@app/shared/controls/assetsBreadcrumbs/models';

@Component({
  selector: 'floor-map',
  templateUrl: './floorMap.component.html',
  styleUrls: ['./floorMap.component.scss'],
  providers: [
    FloorPlanModel,
    FloorModel,
    { provide: FLOOR_PLAN_LIVE_MODE_TOKEN, useClass: LiveProcessingService },
    { provide: FLOOR_PLAN_XY_LOCATION_LIVE_MODE_TOKEN, useClass: LiveProcessingService },
    { provide: FLOOR_SEARCH_COLLECTION_TOKEN, useClass: AssetsCollectionModel },
    { provide: BUILDING_SEARCH_COLLECTION_TOKEN, useClass: AssetsCollectionModel },
    { provide: SITE_SEARCH_COLLECTION_TOKEN, useClass: AssetsCollectionModel },
    { provide: CUSTOMER_SEARCH_COLLECTION_TOKEN, useClass: AssetsCollectionModel },
  ]
})

export class FloorMapComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();
  private floorId: string;
  private buildingId: string;
  private siteId: string;
  private customerId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private planViewService: PlanViewProvider,
    private floorModel: FloorModel,
    private popupsDialogService: PopupsDialogService,
    private elementRef: ElementRef,
    private errorHandler: ErrorHandlerService,
    @Inject(FLOOR_PLAN_LIVE_MODE_TOKEN) private readonly liveProcessingService: LiveProcessingService,
    @Inject(FLOOR_PLAN_XY_LOCATION_LIVE_MODE_TOKEN) private readonly xyLocationliveProcessingService: LiveProcessingService,
    public floorPlan: FloorPlanModel
  ) {
    this.floorPlanConfig();
    this.planViewService.clearStorage();
  }

  public ngOnDestroy(): void {
    this.subscriptions.destroy();
    this.floorPlan.destroy();
  }

  public async ngOnInit() {
    this.floorId = this.activatedRoute.snapshot.queryParams.floorId;
    this.siteId = this.activatedRoute.snapshot.queryParams.siteId;
    this.buildingId = this.activatedRoute.snapshot.queryParams.buildingId;
    this.customerId = this.activatedRoute.snapshot.queryParams.customerId;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.load();
      this.initReload();
    });
  }

  private floorPlanConfig(): void {

    this.floorPlan.options.cache = true;
    this.floorPlan.options.isAreasAnalyticsVisible = true;
    this.floorPlan.options.isDeskAreaVisible = true;
    this.floorPlan.options.isMeetingRoomAreaVisible = true;
    this.floorPlan.options.isReceptionAreaVisible = true;
    this.floorPlan.options.isDevicesMasterVisible = true;

    this.floorPlan.options.isTrafficLinesAnalyticsVisible = true;
    this.floorPlan.options.isDeviceMasterTooltipVisible = true;
    this.floorPlan.options.isPeopleLocationVisible = true;
    this.floorPlan.options.isFloorPlanVisible = true;
    this.floorPlan.options.isGridVisible = false;
    this.floorPlan.options.isDetectionAreasVisible = false;
    this.floorPlan.options.isAreasPeopleCountVisible = true;
    this.floorPlan.options.isDeviceMasterTooltipVisible = true;
    this.floorPlan.options.isTrafficLinesTuningTooltipVisible = true;
    this.floorPlan.options.isDragAndDropForObject = false;
  }

  private async load(): Promise<void> {
    if (this.floorId) {
      this.popupsDialogService.startRelativeLoading(this.elementRef.nativeElement, 100, 'relative-loading-alt');
      this.liveProcessingService.pause();
      this.xyLocationliveProcessingService.pause();
      try {
        this.floorPlan.clearFloor();
        await this.floorModel.load(this.customerId, this.floorId);
        this.floorPlan.resetStorage();
        this.floorPlan.planImageSrcChange();
        await this.floorPlan.loadFloorContent(true);
        this.floorPlan.renderFloor();
      } catch (error) {
        this.popupsDialogService.breackRelativeLoading(this.elementRef.nativeElement);
        this.errorHandler.handleError(error);
        this.floorPlan.planImageSrcChange();
      }
      this.liveProcessingService.continue();
      this.xyLocationliveProcessingService.continue();
    }
  }

  private initReload(): void {

    this.liveProcessingService
      .start(environment.analyticsFloorLiveInterval)
      .pipe(this.subscriptions.register())
      .subscribe();

    this.liveProcessingService
      .onLiveUpdated
      .pipe(mergeMap(() => {
        this.liveProcessingService.pause();
        return this.floorPlan.refresh(true);
      }))
      .pipe(map(() => {
        this.liveProcessingService.continue();
      }))
      .pipe(this.subscriptions.register())
      .subscribe(() => { });

    this.xyLocationliveProcessingService
      .start(environment.xyLocationLiveInterval)
      .pipe(map(() => {
        if (!this.floorPlan.options.isPeopleLocationVisible) {
          this.xyLocationliveProcessingService.pause();
        }
      }))
      .pipe(this.subscriptions.register())
      .subscribe();

    this.xyLocationliveProcessingService
      .onLiveUpdated
      .pipe(map(async () => {
        this.xyLocationliveProcessingService.pause();
        await this.floorPlan.xyLocationRefresh();
      }))
      .pipe(map(() => {
        this.xyLocationliveProcessingService.continue();
      }))
      .pipe(this.subscriptions.register())
      .subscribe();

    this.floorPlan
      .onRender
      .pipe(this.subscriptions.register())
      .subscribe(() => {
        this.liveProcessingService.continue();
        this.popupsDialogService.breackRelativeLoading(this.elementRef.nativeElement);
      });
  }


}
