import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {
    Observable
} from 'rxjs';
import { environment } from '@environments/environment';
import { AuthTypeEnum } from '@app/core';

@Injectable()
export class AuthProxyInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {

        if (request.url.endsWith(environment.sso.tokenEndpoint)) {
            const data = request.body as FormData;
            const grantType = data.get('grant_type');
            if (grantType === AuthTypeEnum.authorizationGrant) {
                if (environment.useProxyForBuild) {
                    const url = environment.apiUrl;
                    request = request.clone({
                        url: url + environment.sso.tokenSSOEndpoint,
                    });
                } else {
                    request = request.clone({
                        url: environment.sso.tokenSSOEndpoint
                    });
                }
            }
            if (grantType === AuthTypeEnum.authorizationSamlGrant) {
                if (environment.useProxyForBuild) {
                    const url = environment.apiUrl;
                    request = request.clone({
                        url: url + environment.sso.tokenSAMLEndpoint,
                    });
                } else {
                    request = request.clone({
                        url: environment.sso.tokenSAMLEndpoint
                    });
                }
            }

        }
        return next.handle(request);
    }

}
