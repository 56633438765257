import { Component } from '@angular/core';
import { AuthenticationProvider } from '@app/core/auth';
import { UserModel } from '@comMobileApp/core/user';

@Component({
  selector: 'com-mobile-app',
  templateUrl: 'comMobileApp.component.html',
  styleUrls: ['comMobileApp.component.scss']
})
export class ComMobileAppComponent {

  constructor(
    private user: UserModel,
    private authenticationProvider: AuthenticationProvider
  ) {
    const userContract = this.authenticationProvider.currentUserValue.toContract();
    this.user.applyContract(userContract);
  }

}
