import { NgModule } from '@angular/core';
import {
    RouterModule,
    Routes
} from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonControlsModule } from 'src/app/shared/controls/commonControls.module';
import { AuthGuard } from '../auth/guards';
import { StatusMonitorRes } from '@app/core';

const routes: Routes = [
    {
        path: 'status-monitor',
        canActivate: [AuthGuard],
        loadChildren: () => import('./statusMonitor.module').then(m => m.StatusMonitorModule),
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        CommonControlsModule
    ],
    exports: [
        RouterModule,
        CommonControlsModule
    ],
    providers: [
        StatusMonitorRes
    ]
})
export class StatusMonitorLazyModule {

}
