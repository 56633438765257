import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import {
  LoginPageComponent,
  LoginByMicrosoftComponent,
  LoginByMicrosoftCallbackComponent,
  LoginInternalComponent,
  LoginBySSOComponent,
  LoginBySSOCallbackComponent
} from './components';

const routes: Routes = [

  {
    path: 'login',
    component: LoginPageComponent,
    children: [
      {
        path: '',
        component: LoginInternalComponent,
        data: { animation: 'LoginInternal' },
      },
      {
        path: 'sso',
        component: LoginByMicrosoftComponent,
        data: { animation: 'LoginByMicrosoft' },
      },
      {
        path: 'sso/callback',
        component: LoginByMicrosoftCallbackComponent
      },
      {
        path: 'sso/saml',
        component: LoginBySSOComponent,
        data: { animation: 'LoginBySSO' },
      },
      {
        path: 'sso/saml/callback',
        component: LoginBySSOCallbackComponent
      },
      {
        path: 'sso/:tenantName',
        component: LoginByMicrosoftComponent,
        data: { animation: 'LoginByMicrosoft' },
      },
    ]
  },
  {
    path: 'be/sso',
    component: LoginPageComponent,
    children: [
      {
        path: ':tenantName/callback',
        component: LoginByMicrosoftCallbackComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AuthRoutingModule { }
