import {
  NgModule
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { TeamsRoutingModule } from './teamsRouting.module';
import {
  FloorMapComponent,
  TeamsConfigurationsComponent
} from './components';
import { TeamsConfigurationsModel } from '@app/core';
import { FloorPlanModule } from '../floorPlan';


const COMPONENTS = [
  TeamsConfigurationsComponent,
  FloorMapComponent
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    FloorPlanModule,
    TeamsRoutingModule
  ],
  providers: [
    TeamsConfigurationsModel
  ]
})
export class TeamsModule { }
