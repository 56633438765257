import { inject } from '@angular/core';
import {
  Routes
} from '@angular/router';
import { ComMobileAppComponent } from './comMobileApp.component';
import { ComMobileAppPermission } from '@app/modules/auth/guards';
import { ComMobileAppConfig } from './comMobileApp.config';

export const ComMobileAppRoutes: Routes = [
  {
    path: 'installer',
    component: ComMobileAppComponent,
    canActivate: [() => inject(ComMobileAppPermission).canActivate()],
    loadChildren: () => import('./ionicApp').then(m => m.IonicAppRoutes),
    providers: [
      ComMobileAppConfig.providers
    ]
  }  
];
