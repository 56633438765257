import { CommonModule } from '@angular/common';
import {
    BrowserModule,
    EventManager,
    Title
} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './appRouting.module';
import { IonicModule } from '@ionic/angular';
import { AppComponent } from './app.component';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule
} from '@angular/common/http';
import {
    TranslateLoader,
    TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    HttpErrorInterceptor,
    ProxyInterceptor
} from '@interceptors';
import { CustomEventManager } from '@app/shared/utils/custom-event-manager';
import { environment } from '@environments/environment';
import { HttpRequestInterceptor } from './shared';
import {
    DevMockBackendInterceptor,
    MockBackendInterceptor
} from 'src/testing';
import { SharedModule } from './shared/shared.module';
import { GoogleChartsModule } from 'angular-google-charts';
import { ApplicationReferencesModule } from './modules/applicationReferences.module';
import { LayoutModule } from './layout';
import {
    ErrorHandlerService,
    GoogleAnalyticsService
} from '@appServices';
import { ComMobileAppModule } from '@comMobileApp/startup/comMobileApp.module';

export const isMock = environment.mock;
export const HttpLoaderFactory = (httpClient: HttpClient): any => new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json?v=' + environment.version);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        LayoutModule,
        SharedModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ApplicationReferencesModule,
        ComMobileAppModule,
        AppRoutingModule,
    ],
    providers: [
        ErrorHandlerService,
        { provide: Window, useValue: window },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: isMock ? MockBackendInterceptor : HttpRequestInterceptor,
            multi: true
        },
        {
            // using fake backend in place of Http service for backend-less development
            provide: HTTP_INTERCEPTORS,
            useClass: !isMock ? DevMockBackendInterceptor : HttpRequestInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ProxyInterceptor,
            multi: true
        },
        {
            provide: EventManager,
            useClass: CustomEventManager
        },
        Title,
        { provide: GoogleAnalyticsService, useValue: new GoogleAnalyticsService() }
    ],
    exports: [],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

}
