import {
    Component,
    OnInit
} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {
    ConfigSSOProvider,
    GlobalRes
} from '@app/core';
import {
    ErrorHandlerService,
    SubscriptionNotifier
} from '@app/shared';
import {
    map,
    mergeMap
} from 'rxjs/operators';
import { AuthRoutesConstants } from '../../constants';
import { AuthenticationProvider } from '@app/core/auth/providers';

@Component({
    selector: 'login-by-sso-callback',
    template: '',
    host: {
        class: 'login-by-sso-callback'
    }
})

export class LoginBySSOCallbackComponent implements OnInit {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    constructor(
        private configSSOProvider: ConfigSSOProvider,
        private activatedRoute: ActivatedRoute,
        private authenticationProvider: AuthenticationProvider,
        private router: Router,
        private errorHandlerService: ErrorHandlerService,
        private res: GlobalRes
    ) {
    }

    public async ngOnInit() {
        await this.res.init();
        this.activatedRoute
            .queryParams
            .pipe(map(value => {
                if (!value.accessCode || !value.email) {
                    this.configSSOProvider.authOptions.error = this.res.accessDenied;
                    this.router.navigate([AuthRoutesConstants.LOGIN_SSO_ROUTE]);
                }
                return value;
            }))
            .pipe(mergeMap((value) => this.authenticationProvider.loginUsingBySamlAccessCode(
                value.accessCode,
                value.email
            )))
            .pipe(this.subscriptions.register())
            .subscribe({
                next: () => {
                    window.location.href = this.activatedRoute.snapshot.queryParams.redirect_uri || this.authenticationProvider.getDefaultRoutApp();
                },
                error: error => {
                    this.configSSOProvider.authOptions.error = this.errorHandlerService.getUserMessage(error) || this.res.organizationNameIsWrong;
                    this.router.navigate([AuthRoutesConstants.LOGIN_SSO_ROUTE]);
                }
            });
    }

}
