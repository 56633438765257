import {
    animate,
    group,
    query,
    style,
    transition,
    trigger
} from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component
} from '@angular/core';

const ROUTER_TRANSITION = trigger('routerTransition', [
    transition('* <=> *', [
        query(':enter, :leave', style({}), { optional: true }),
        group([
            query(':enter', [style({ opacity: 0 }), animate('0.5s ease-in-out', style({ opacity: 1 }))], { optional: true }),
            query(':leave', [style({ opacity: 1 }), animate('0.5s ease-in-out', style({ opacity: 0 }))], { optional: true })
        ])
    ])
]);

@Component({
    selector: 'login-page',
    templateUrl: 'loginPage.component.html',
    styleUrls: ['./loginPage.component.scss'],
    animations: [ROUTER_TRANSITION],
    host: {
        class: 'login-page'
    }
})

export class LoginPageComponent implements AfterViewInit {

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
    ) {

    }

    public ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    public getState(outlet: any) {
        return (outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation) || '';
    }

}
