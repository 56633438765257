import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';
import { environmentLoader as environmentLoaderPromise } from './environments/environmentLoader';

environmentLoaderPromise.then((env) => {
  if (env.production) {
    enableProdMode();
  }
  if (env.apiUrl) {
    environment.apiUrl = env.apiUrl;
  }
  if (env.googleAnalyticsId) {
    environment.googleAnalyticsId = env.googleAnalyticsId;
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});

