import { inject, NgModule } from '@angular/core';
import {
    RouterModule,
    Routes
} from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonControlsModule } from 'src/app/shared/controls/commonControls.module';
import {
    CpmsPermission
} from '../auth/guards';
import {
    CpmsRes,
    FloorPlanRes
} from '@app/core';
import { MainLayoutComponent } from 'src/app/layout';
import { GlobalResResolver } from '@app/shared';

const routes: Routes = [
    {
        path: 'cpms',
        component: MainLayoutComponent,
        canActivate: [CpmsPermission],
        resolve: { i18n: () => inject(GlobalResResolver).resolve() },
        loadChildren: () => import('./cpms.module').then(m => m.CpmsModule),
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        CommonControlsModule
    ],
    exports: [
        RouterModule,
        CommonControlsModule
    ],
    providers: [
        CpmsRes,
        FloorPlanRes
    ]
})
export class CpmsLazyModule {

}
