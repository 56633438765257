import {
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import { GlobalRes } from '@app/core/i18n';
import {
    ErrorHandlerService,
    PopupsDialogService,
    SubscriptionNotifier
} from '@app/shared';
import {
    AuthStorageRemoteProvider
} from '@app/storage';
import {
    HOME_UNAUTHORIZED_ROUTE
} from '@appConstants';
import {
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { environment } from '@environments/environment';
import { ConfigSSOProvider } from '@app/core';

@Component({
    selector: 'login-by-sso',
    templateUrl: 'loginBySSO.component.html',
    styleUrls: ['./loginBySSO.component.scss'],
    host: {
        class: 'login-by-sso'
    }
})

export class LoginBySSOComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();
    public get formControls() { return this.loginForm.controls; }
    public loginForm: FormGroup;
    public error: string;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authStorageRemoteProvider: AuthStorageRemoteProvider,
        private popups: PopupsDialogService,
        private elementRef: ElementRef,
        private errorHandlerService: ErrorHandlerService,
        private configSSOProvider: ConfigSSOProvider,
        public res: GlobalRes
    ) {
        this.error = '';
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required]
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnInit(): void {
        this.loginForm.controls.email.setValue(this.configSSOProvider.authOptions.lastTenantName);
    }

    public isDisabledLogin(): boolean {
        return !this.loginForm.valid;
    }

    public back(): void {
        this.router.navigate([HOME_UNAUTHORIZED_ROUTE], { queryParams: this.activatedRoute.snapshot.queryParams.redirect_uri ? { redirect_uri: this.activatedRoute.snapshot.queryParams.redirect_uri } : {} });
    }

    public login() {
        this.configSSOProvider.saveTenantName(this.formControls.email.value);
        this.authStorageRemoteProvider
            .externalLogin(this.formControls.email.value)
            .pipe(this.subscriptions.register())
            .subscribe();
        // this.error = '';
        // this.popups.startRelativeLoading(this.elementRef.nativeElement, 100, 'relative-loading-alt');
        // this.authStorageRemoteProvider
        //     .getSSOSettings(this.formControls.tenantName.value)
        //     .pipe(this.subscriptions.register())
        //     .subscribe(
        //         (value: SSOSettingsContract) => {
        //             this.configSSOProvider.authOptions.clientId = value.clientId;
        //             this.configSSOProvider.authOptions.authority = value.authority;
        //             this.configSSOProvider.authOptions.redirectUri = this.activatedRoute.snapshot.queryParams.redirect_uri;
        //             this.configSSOProvider.authOptions.tenantName = this.formControls.tenantName.value;
        //             this.configSSOProvider.saveSettings();
        //             this.loginRedirect(value);
        //         },
        //         error => {
        //             this.error = this.errorHandlerService.getUserMessage(error) || this.res.organizationNameIsWrong;
        //             this.popups.breackRelativeLoading(this.elementRef.nativeElement);
        //         }
        //     );
    }


}
